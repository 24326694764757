import cx from 'classnames'
import { motion } from 'framer-motion'
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { AnalyticsEventName, getProductEventPayload } from '@lib/analytics'
import { AnalyticsContext } from '@lib/analytics-context'
import { itemAnimation } from '@lib/animate'
import { CartContext } from '@lib/cart/context'
import {
  type Filter,
  getFallbackVariantFromOptions,
  getVariantFromOptions,
} from '@lib/product'
import {
  getFilteredProductVariant,
  getProductTitle,
  getSurfacedOption,
} from '@lib/product-card'
import { type CollectionProduct } from '@lib/product-collection'
import { type OptionValue, getProductOptions } from '@lib/product-options'
import { getLinkPageUrl } from '@lib/routes'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'

import ProductAdd from '@blocks/product/product-add'
import ProductOptionList from '@blocks/product/product-option-list'
import ProductPrice from '@blocks/product/product-price'
import ProductThumbnail from '@blocks/product/product-thumbnail'
import { ButtonColor, ButtonVariant } from '@components/buttons/button'
import Chip from '@components/chip'
import SimpleLink from '@components/simple-link'

interface ProductCardProps {
  product: CollectionProduct
  preferredOption?: OptionValue
  filters?: Filter[]
  showThumbnails?: boolean
  showPrice?: boolean
  showOption?: boolean
  showQuickAdd?: boolean
  onClick?: () => void
  className?: string
  titleClassName?: string
}

const surfacedOptionVariantLimit = 3

const ProductCard = forwardRef<HTMLDivElement, ProductCardProps>(
  (
    {
      product,
      preferredOption,
      filters = [],
      showThumbnails = false,
      showPrice = false,
      showOption = false,
      showQuickAdd = false,
      onClick,
      className,
      titleClassName,
    },
    ref,
  ) => {
    const { triggerAnalyticsEvent } = useContext(AnalyticsContext)
    const { openCartAfterAddingItems, addItemsToCart, toggleCart } =
      useContext(CartContext)
    const { currencyCode } = useContext(ShopContext)
    const strings = useContext(StringsContext)

    const [activeVariant, setActiveVariant] = useState(() =>
      getFilteredProductVariant(product, filters ?? [], preferredOption),
    )
    const [title, setTitle] = useState(() =>
      getProductTitle(product, activeVariant ?? undefined, preferredOption),
    )

    // Product URL
    const productUrl = useMemo(() => {
      let productSlug = product.slug.current

      // Get product slug for compacted product from mapping
      if (
        activeVariant?.variantID &&
        product.variantIdProductSlugMap?.[activeVariant.variantID]
      ) {
        productSlug = product.variantIdProductSlugMap[activeVariant.variantID]
      }

      return getLinkPageUrl('product', productSlug, {
        variant: activeVariant ? `${activeVariant.variantID}` : '',
      })
    }, [activeVariant, product])

    // Surfaced option data
    const surfacedOptionOverThumbnail = useMemo(
      () =>
        getSurfacedOption(
          product.options,
          product.surfaceOptions?.overThumbnail,
        ),
      [product.options, product.surfaceOptions],
    )

    const surfacedOptionOverTitle = useMemo(
      () =>
        getSurfacedOption(product.options, product.surfaceOptions?.overTitle),
      [product.options, product.surfaceOptions],
    )

    const surfacedOptionOverThumbnailProductOptions = useMemo(() => {
      if (!activeVariant || !surfacedOptionOverThumbnail) {
        return
      }

      return getProductOptions(
        surfacedOptionOverThumbnail,
        activeVariant.options,
        null,
        product.galleryPhotos ?? [],
        product.optionSettings ?? [],
        product.variants ?? [],
        preferredOption?.value,
        surfacedOptionVariantLimit,
      )
    }, [activeVariant, preferredOption, product, surfacedOptionOverThumbnail])

    const surfacedOptionOverTitleProductOptions = useMemo(() => {
      if (!activeVariant || !surfacedOptionOverTitle) {
        return
      }

      return getProductOptions(
        surfacedOptionOverTitle,
        activeVariant.options,
        null,
        product.galleryPhotos ?? [],
        product.optionSettings ?? [],
        product.variants ?? [],
        preferredOption?.value,
      )
    }, [activeVariant, preferredOption, product, surfacedOptionOverTitle])

    const showSurfacedOptionOverThumbnail =
      showOption &&
      !!surfacedOptionOverThumbnail &&
      !!activeVariant &&
      !!surfacedOptionOverThumbnailProductOptions
    const showSurfacedOptionOverTitle =
      showOption &&
      !!surfacedOptionOverTitle &&
      !!activeVariant &&
      !!surfacedOptionOverTitleProductOptions

    const updateActiveVariant = useCallback(
      (optionName: string, optionValue: string) => {
        let newVariant = activeVariant
          ? getVariantFromOptions(
              product.variants ?? [],
              activeVariant.options,
              optionName,
              optionValue,
            )
          : null

        if (!newVariant) {
          newVariant = getFallbackVariantFromOptions(
            product.variants ?? [],
            optionName,
            optionValue,
          )
        }

        if (newVariant && newVariant.variantID !== activeVariant?.variantID) {
          setActiveVariant(newVariant)
        }
      },
      [activeVariant, product, setActiveVariant],
    )

    const handleAddToCart = useCallback(async () => {
      if (!activeVariant) {
        return
      }

      const quantity = 1
      const isSuccessful = await addItemsToCart([
        {
          id: activeVariant.variantID,
          quantity,
        },
      ])

      if (isSuccessful) {
        const eventPayload = getProductEventPayload(
          product.title,
          [
            {
              variantId: activeVariant.variantID,
              options: activeVariant.options,
              price: activeVariant.price,
              comparePrice: activeVariant.comparePrice,
              quantity,
            },
          ],
          currencyCode,
        )
        triggerAnalyticsEvent(AnalyticsEventName.AddToCart, eventPayload)

        if (openCartAfterAddingItems) {
          toggleCart(true)
        }
      }
    }, [
      activeVariant,
      addItemsToCart,
      currencyCode,
      openCartAfterAddingItems,
      product,
      toggleCart,
      triggerAnalyticsEvent,
    ])

    useEffect(() => {
      setTitle(
        getProductTitle(product, activeVariant ?? undefined, preferredOption),
      )
    }, [activeVariant, preferredOption, product])

    return (
      <motion.div
        ref={ref}
        variants={itemAnimation}
        className={cx(
          'h-full flex flex-col justify-end relative group z-0',
          className,
        )}
      >
        {!!product.comparePrice && (
          <Chip
            className="absolute top-0 right-0 z-[1]"
            label={strings.productDiscountText.replace(
              /{percent}/gi,
              Math.ceil(
                ((product.comparePrice - product.price) /
                  product.comparePrice) *
                  100,
              ).toString(),
            )}
          />
        )}

        {showThumbnails && !!activeVariant && (
          <div className="relative flex flex-col items-center">
            <SimpleLink href={productUrl} className="w-full">
              <ProductThumbnail
                galleryPhotos={product.galleryPhotos}
                listingPhotos={product.listingPhotos}
                activeVariant={activeVariant}
              />
            </SimpleLink>

            {showQuickAdd && activeVariant.inStock && (
              <div
                className={cx(
                  'absolute bottom-0 inset-x-0 z-30 text-center mx-4 mb-8 opacity-0 invisible',
                  'translate-y-1/2 transition-all will-change-transform',
                  'group-hover:opacity-100 group-hover:visible group-hover:translate-y-0',
                )}
              >
                <ProductAdd
                  variant={ButtonVariant.FILLED}
                  color={ButtonColor.DEFAULT}
                  onClick={handleAddToCart}
                >
                  {strings.buttonAddToCart}
                </ProductAdd>
              </div>
            )}

            {showSurfacedOptionOverThumbnail && (
              <div className="absolute bottom-3 right-3 z-30 hidden group-hover:flex">
                <ProductOptionList
                  productOptions={surfacedOptionOverThumbnailProductOptions}
                  hasShowMore={
                    surfacedOptionOverThumbnail.values.length >
                    surfacedOptionVariantLimit
                  }
                  showMoreUrl={productUrl}
                  isInProductCard
                  onSelect={(optionValue) =>
                    updateActiveVariant(
                      surfacedOptionOverThumbnail.name,
                      optionValue,
                    )
                  }
                />
              </div>
            )}
          </div>
        )}

        <div
          className={cx(
            'flex flex-col sm:flex-row items-start justify-between gap-2 p-3 leading-tight uppercase',
            titleClassName,
          )}
        >
          <SimpleLink
            href={productUrl}
            onClick={onClick}
            onBeforeInput={onClick}
            tabIndex={0}
            className={cx('text-xs block no-underline text-current', {
              'group-hover:hidden': showSurfacedOptionOverTitle,
            })}
          >
            {title}
          </SimpleLink>

          {showSurfacedOptionOverTitle && (
            <ProductOptionList
              productOptions={surfacedOptionOverTitleProductOptions}
              isInProductCard
              onSelect={(optionValue) =>
                updateActiveVariant(surfacedOptionOverTitle.name, optionValue)
              }
              className="hidden group-hover:flex"
            />
          )}

          {showPrice && (
            <ProductPrice
              className="text-xs"
              price={activeVariant?.price ?? product.price}
              comparePrice={activeVariant?.comparePrice ?? product.comparePrice}
            />
          )}
        </div>
      </motion.div>
    )
  },
)

ProductCard.displayName = 'ProductCard'

export default ProductCard
